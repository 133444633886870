/** Ionic CSS Variables **/
:root {
  --ion-padding: 12px;
  --ion-background-color: var(--ion-color-light-tint);
  /** primary **/
  --ion-color-primary: #1d6093;
  --ion-color-primary-rgb: 29, 96, 147;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1a5481;
  --ion-color-primary-tint: #34709e;
  /** secondary **/
  --ion-color-secondary: #95e2c1;
  --ion-color-secondary-rgb: 149, 226, 193;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #83c7aa;
  --ion-color-secondary-tint: #a0e5c7;
  /** tertiary **/
  --ion-color-tertiary: #94d6ea;
  --ion-color-tertiary-rgb: 148, 214, 234;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #82bcce;
  --ion-color-tertiary-tint: #9fdaec;
  /** success **/
  --ion-color-success: #95e2c1;
  --ion-color-success-rgb: 149, 226, 193;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #83c7aa;
  --ion-color-success-tint: #a0e5c7;
  /** warning **/
  --ion-color-warning: #ffd789;
  --ion-color-warning-rgb: 255, 215, 137;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bd79;
  --ion-color-warning-tint: #ffdb95;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f2f2f2;
  --ion-color-light-rgb: 242, 242, 242;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d5d5;
  --ion-color-light-tint: #f3f3f3;
}
:root .inverted {
  /** primary **/
  --ion-color-primary: #95e2c1;
  --ion-color-primary-rgb: 149, 226, 193;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #83c7aa;
  --ion-color-primary-tint: #a0e5c7;
  /** secondary **/
  --ion-color-secondary: #1d6093;
  --ion-color-secondary-rgb: 29, 96, 147;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1a5481;
  --ion-color-secondary-tint: #34709e;
}

@font-face {
  font-family: "Merriweather";
  src: url("../fonts/Merriweather/Merriweather-Bold.ttf");
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.ttf");
}
:root {
  font-family: Lato;
  --ion-font-family: Lato;
}
:root h1,
:root h2,
:root h3,
:root h4,
:root h5,
:root ion-title,
:root .title-font {
  font-family: Merriweather;
  --ion-font-family: Merriweather;
}

:root {
  --btn-height: 40px;
}
:root ion-button {
  border-radius: var(--border-radius-size) !important;
  --border-radius: var(--border-radius-size) !important;
  font-size: 0.9rem;
  font-weight: bold;
  height: var(--btn-height) !important;
}
:root ion-button.button-has-icon-only {
  width: var(--btn-height) !important;
  --padding-top: 6px !important;
  --padding-end: 6px !important;
  --padding-bottom: 6px !important;
  --padding-start: 6px !important;
}
:root .table-actions {
  padding: 0;
}
:root .table-actions ion-button {
  margin: 0 4px !important;
  height: 30px !important;
  width: 30px !important;
  --padding-top: 4px !important;
  --padding-end: 4px !important;
  --padding-bottom: 4px !important;
  --padding-start: 4px !important;
}

ion-label.form-label {
  color: var(--ion-color-dark-tint);
  font-size: 1rem !important;
  width: 100% !important;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-label.form-label.required:after {
  margin-left: 2px;
  font-weight: bold;
  font-size: large;
  color: var(--ion-color-danger);
  content: "*";
  line-height: 1rem;
}

ion-input {
  --height: var(--btn-height) !important;
  --background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-searchbar {
  --background: rgba(0, 0, 0, 0.05) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: var(--border-radius-size) !important;
  --icon-color: var(--ion-color-medium) !important;
  --clear-button-color: var(--ion-color-medium) !important;
}

ion-textarea {
  --background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-select {
  height: var(--btn-height) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  padding: 0 16px;
  margin-top: 8px;
}

ion-text {
  font-size: 1rem !important;
}

ion-card {
  display: flex;
  flex-direction: column;
}
ion-card ion-card-content {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--ion-color-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary-shade);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-tint);
}