@font-face {
  font-family: "Merriweather";
  src: url("../fonts/Merriweather/Merriweather-Bold.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.ttf");
}

:root {
  font-family: Lato;
  --ion-font-family: Lato;

  h1,
  h2,
  h3,
  h4,
  h5,
  ion-title,
  .title-font {
    font-family: Merriweather;
    --ion-font-family: Merriweather;
  }
}
