:root {
  --border-radius-size: 7px;
  --ion-padding: 24px;
  --ion-margin: 16px;
}

.bg-transparent {
  --background: transparent !important;
  background: transparent !important;
}

.color-primary-contrast {
  --color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary-contrast);
}

.color-success {
  --color: var(--ion-color-success);
  color: var(--ion-color-success);
}

a {
  text-decoration: none;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-interactive-disabled {
  .sc-ion-label-md-h:not(.item-multiple-inputs),
  .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }
}

.native-input[disabled].sc-ion-input-ios:not(.cloned-input) {
  opacity: 1;
}
.native-textarea[disabled].sc-ion-textarea-md:not(.cloned-input) {
  opacity: 1;
}
