::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--ion-color-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary-shade);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-tint);
}
