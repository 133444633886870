:root {
  --btn-height: 40px;

  ion-button {
    border-radius: var(--border-radius-size) !important;
    --border-radius: var(--border-radius-size) !important;
    font-size: 0.9rem;
    font-weight: bold;
    height: var(--btn-height) !important;
    // width: var(--btn-height) !important;
  }
  ion-button.button-has-icon-only {
    width: var(--btn-height) !important;
    --padding-top: 6px !important;
    --padding-end: 6px !important;
    --padding-bottom: 6px !important;
    --padding-start: 6px !important;
  }

  .table-actions {
    padding: 0;
    ion-button {
      margin: 0 4px !important;
      height: 30px !important;
      width: 30px !important;
      --padding-top: 4px !important;
      --padding-end: 4px !important;
      --padding-bottom: 4px !important;
      --padding-start: 4px !important;
    }
  }
}
