.animated-slow {
  .transition {
    transition: all 1.5s ease !important;
    --transition: all 1.5s ease !important;
  }
}

.animated-medium {
  .transition {
    transition: all 0.5s ease !important;
    --transition: all 0.5s ease !important;
  }
}

.animated-fast {
  .transition {
    transition: all 0.2s ease !important;
    --transition: all 0.2s ease !important;
  }
}

.no-animation {
  .transition {
    transition: none !important;
    --transition: none !important;
  }
}
