ion-label.form-label {
  color: var(--ion-color-dark-tint);
  font-size: 0.8rem;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.required {
    &:after {
      margin-left: 2px;
      font-weight: bold;
      font-size: large;
      color: var(--ion-color-danger);
      content: "*";
      line-height: 1rem;
    }
  }
}

ion-input {
  --height: var(--btn-height) !important;
  --background: var(--ion-color-light-shade) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-searchbar {
  --background: var(--ion-color-light-shade) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: var(--border-radius-size) !important;
  --icon-color: var(--ion-color-medium-shade) !important;
  --clear-button-color: var(--ion-color-medium-shade) !important;
}

ion-textarea {
  --background: var(--ion-color-light-shade) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-select {
  height: var(--btn-height) !important;
  background: var(--ion-color-light-shade) !important;
  --padding-start: 24px !important;
  color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-item {
  --background: 0;
}
